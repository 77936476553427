import { create_element, get_offset_top } from "../../js/utils";
import { items_animation_delay } from "../../js/functions";
import Rotating_Items from "../rotating_items/rotating_items";
import Infite_Scrolling_Carousel from "../carousel";
import Sticky_Element from "../StickyElement";
import retina_support from "../../js/retina.js";

retina_support();

let is_mobile = window.outerWidth < 767;

let header = {
    el: document.getElementById('section_header'),
    sticky: null,
}

let logos = {
    el: document.querySelector('#trusted_logos .gallery'),
    swiper: null,
    carousel: null,
}

let color_boxes = {
    el: document.querySelector('.color_boxes .e-con-inner'),
    swiper: null,
}

let resize_timeout = null;
window.addEventListener('resize',()=>{
    clearTimeout(resize_timeout);
    resize_timeout = setTimeout(on_resize, 50);
});

function on_resize(){

    is_mobile = window.outerWidth < 767;

    if( is_mobile ) {

        if( header.sticky ) {
            if( header.sticky.state == 'enabled' ) {
                header.sticky.disable();
            }
        }

        logos_mobile_init();
        color_boxes_mobile_init();
        tabs_mobile_open_all();

    }
    else {
        if( header.sticky ) {
            if( header.sticky.state == 'disabled' ) {
                header.sticky.enable();
            }
            header.sticky.refresh();
        }
    }
    
}

function init(){
    
    header_init();
    
    logo_scroll_to_top();

    nav_init();

    // banner steps - hover progress bar
    create_element({class_name: 'progress_bar', append_to: '.banner_steps .step-2'});
    
    rotating_items_init();
    
    if( !is_mobile ) {
        // gallery items stagger animation
        document.querySelectorAll('.gallery_animate_items').forEach(el=>{
            items_animation_delay(el, '.gallery-item', 150);
        });
    }
    
    color_tabs_init();

    load_swiper(()=>{
        color_boxes_swiper();
        swiper_center_single_init('.testimonials_carousel', { spaceBetween: 80, delay: 4000});
        swiper_center_single_init('.ff_video_carousel', { spaceBetween: 70});

        if( is_mobile ) {
            logos_mobile_init();
        }
    });

    tabs_mobile_support();

    if( is_mobile ) {
        tabs_mobile_open_all();
    }
}
document.addEventListener('DOMContentLoaded', init);

function rotating_items_init(){
    document.querySelectorAll('.rotating_items').forEach(item=>{
        let options = {
            animation: 'fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both',
        };
    
        let animation = item.dataset.animation;
        if( animation ) {
            options.animation = animation;
        }
    
        let interval = item.dataset.interval;
        if( interval ) {
            options.interval = parseInt(interval);
        }
    
        new Rotating_Items(item, options);
    })
}

function color_tabs_init(){
    let container = document.getElementById('color_tabs');
    if( !container ) return;
    container.querySelectorAll('.e-n-tab-title').forEach((item, index)=>{
        item.addEventListener('click', ()=>{
            let even = (index+1) % 2 == 0;
            container.style.backgroundColor = even ? '#e6fef4' : '#f9f3fe';
        });
    })
}

function header_init(){
    if( is_mobile ) return;
    header.sticky = new Sticky_Element(header.el, {
        on_activate: ()=>{
            header.el.style.animation = 'fade-in-top-short 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both';
        },
        on_deactivate: ()=>{
            header.el.style.animation = 'fade-in-bottom-short 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both';
        },
        observer_options: {
            rootMargin: "200px",
        }
    });
}

function nav_init(){
    document.querySelectorAll('.header_nav a').forEach(item=>{
        item.addEventListener('click',(e)=>{
            e.preventDefault();
            e.stopPropagation();
            let target = document.querySelector(item.attributes.href.value);
            if( !target) return;
            let offset = item.dataset.offset ?? default_offset;
            let top = target.offsetTop - parseInt(offset);
            window.scrollTo({
                top,
                behavior: "smooth",
            });
        })
    })

}

function swiper_center_single_init(el, options={}){
    if( typeof el === 'string' ) {
        el = document.querySelector(el);
    }
    if( !el ) return;

    let speed = 600;

    const swiper = new Swiper(el, {
        slidesPerView: 'auto',
        autoplay: {
            delay: options.delay ?? 3000,
        },
        loop: true,
        speed,
        spaceBetween: options.spaceBetween ?? 70,
        centeredSlides: true,
        breakpoints: {
            300: {
                spaceBetween: 20,
            },
            1024: {
                spaceBetween: options.spaceBetween ?? 70,
            }
        }
    });

    el.addEventListener('pointerup', ()=>{
        setTimeout(()=>{
            // swiper.autoplay.start();
            swiper.autoplay.run();
        }, speed);
    })

    return swiper;
    
}

function swiper_marquee(el, options={}){
    if( typeof el === 'string' ) {
        el = document.querySelector(el);
    }
    if( !el ) return;

    let initial_speed = options.speed ?? 3500;
    let drag_speed = 600;

    let swiper_options = {
        slidesPerView: 'auto',
        centeredSlides: true,
        autoplay: {
            delay: 1,
        },
        loop: true,
        speed: initial_speed,
        spaceBetween: options.spaceBetween ?? 60,
    }

    const swiper = new Swiper(el, swiper_options);

    let drag_timeout = null;

    // el.addEventListener('pointerdown', drag);
    // el.addEventListener('pointerup', restore_initial);
    el.addEventListener('mousedown', drag);
    el.addEventListener('mouseup', restore_initial);

    function restore_initial(){
        if( swiper.destroyed ) return;
        drag_timeout = setTimeout(()=>{
            swiper.params.speed = initial_speed;
            swiper.autoplay.start();
            swiper.autoplay.run();
        }, drag_speed);
    }
    
    function drag(){
        if( swiper.destroyed ) return;
        clearTimeout(drag_timeout);
        swiper.params.speed = drag_speed;
    }

    return swiper;
}

function logo_scroll_to_top(){
    const logo = document.getElementById('site_logo');
    if( !logo ) return;
    logo.style.cursor = 'pointer';
    logo.addEventListener('click', ()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }) 
}

function color_boxes_swiper(){
    // add classes for swiper

    let swiper_wrapper = document.createElement('div');
    swiper_wrapper.classList.add('swiper-wrapper');
    color_boxes.el.append(swiper_wrapper);

    // let swiper_wrapper = color_boxes.el.querySelector('.e-con-inner')
    // swiper_wrapper.style.transitionTimingFunction = 'linear';
    // swiper_wrapper.classList.add('swiper-wrapper');
    color_boxes.swiper_wrapper = swiper_wrapper;

    color_boxes.el.querySelectorAll('.elementor-element').forEach((item, index)=>{
        if( (index % 2) == 1 ) {
            item.classList.add('even');
        }
        item.classList.add('swiper-slide');
        swiper_wrapper.append(item);
    })

    if( !is_mobile ){
        color_boxes.swiper = swiper_marquee(color_boxes.el, { spaceBetween: 60, delay: 2000 });
    } else {
        color_boxes_mobile_init();
    }
}

function color_boxes_mobile_init(){
    color_boxes.swiper_wrapper.style.transitionTimingFunction = '';
    color_boxes.swiper = swiper_center_single_init(color_boxes.el, { delay: 3500 });
}

function logos_mobile_init(){

    if( logos.carousel || !logos.el ) return;

    let br = logos.el.querySelector('br');
    if( br ) br.remove();

    logos.el.style.gap = '50px';
    logos.carousel = new Infite_Scrolling_Carousel(logos.el, { duration: 1000, clone: 1, calculate_width: true});
}

function tabs_mobile_support(){
    let transition_duration = 10;
    document.querySelectorAll('.elementor-widget-n-tabs').forEach(tabs => {
        tabs.querySelectorAll('.e-n-tab-title').forEach(tab_title=>{
            tab_title.addEventListener('click', ()=>{
                if( !is_mobile ) return;
                setTimeout(()=>{
                    window.scrollTo({
                        top: get_offset_top(tab_title),
                        behavior: "smooth",
                    });
                }, transition_duration)
            });
        })
    })
}

function tabs_mobile_open_all(){
    document.querySelectorAll('.elementor-widget-n-tabs.mobile_open_all').forEach(container=>{
        container.querySelectorAll('.e-n-tab-title').forEach(title=>{
            title.ariaSelected=true
        })
        let contents = container.querySelector('.e-n-tabs-content').children;
        for(let i = 0; i < contents.length; i++){
            contents[i].classList.add('e-active');
        }
    })
}