export function sticky_element(element, options={}){

    if( typeof element === 'string' ) {
        element = document.querySelector(element);
    }

    if( !element ) return;
    
    // create container element
    let container = document.createElement('div');
    container.classList.add('sticky_spacer');
    element.after(container);
    container.append(element);
    
    // set spacer to avoid jumping on activate
    container.style.minHeight = container.offsetHeight + 'px'; 

    // set width for position fixed activate
    element.style.width = element.offsetWidth + 'px';

    let top = options.top ?? 0;

    let callback = function(entries, observer){
        entries.forEach((entry) => {
            if( !entry.isIntersecting ) {

                element.classList.add('stick_active');
                element.style.position = 'fixed';
                element.style.top = top;
                element.style.zIndex = 100;

                if( typeof options.on_activate === 'function' ) {
                    options.on_activate(element, observer);
                }

            } else {

                element.classList.remove('stick_active');
                element.style.position = '';
                element.style.top = '';
                element.style.zIndex = '';

                if( typeof options.on_deactivate === 'function' ) {
                    options.on_deactivate(element, observer);
                }
            }
        })
    }

    let observer_options = options.observer_options ?? {};
    let observer = new IntersectionObserver(callback, observer_options);
    observer.observe(container);

}

export function active_section_indicator(triggers, targets, options={}){
    
    if( typeof triggers === 'string' ) {
        triggers = document.querySelectorAll(triggers);
    }

    if( typeof targets === 'string' ) {
        targets = document.querySelectorAll(targets);
    }
    
    let current = null;
    
    let last_scroll_y = null;
    let scroll_direction = 'down';

    let down_callback = function(entries, observer){

        if( last_scroll_y === null ) {
            // on load
            last_scroll_y = window.scrollY;
            return;
        }
        
        scroll_direction = window.scrollY > last_scroll_y ? 'down' : 'up';
        last_scroll_y = window.scrollY;
        
        if( scroll_direction != 'down' ) return;

        for( let i = 0; i < entries.length; i++ ) {
            let item = entries[i];
            if( !item.isIntersecting ) {
                activate(item.target.nextSibling);
                break;
            }
        }
    }

    let up_callback = function(entries, observer){
        if( scroll_direction != 'up' ) return;
        for( let i = 0; i < entries.length; i++ ) {
            let item = entries[i];
            if( item.isIntersecting ) {
                activate(item.target);
                break;
            }
        }
    }

    function activate(element){
        deactivate_previous();
        current = element;
        current.classList.add('active');
        current.el_target.classList.add('active');
    }

    function deactivate_previous(){
        if( !current ) return;
        current.classList.remove('active');
        current.el_target.classList.remove('active');
    }

    let observer_down = new IntersectionObserver(down_callback, {});

    let observer_up = new IntersectionObserver(up_callback, {
        threshold: .5,
    });

    triggers.forEach((trigger, index)=>{
        trigger.el_target = targets[index];
        trigger.el_target.el_trigger = trigger;
        observer_down.observe(trigger);
        observer_up.observe(trigger);
    })

    activate(triggers[0]);

    return {
        current,
        activate
    };

}

export function onscreen(el, options={}){
    if ( typeof el === 'string' ) {
        el = document.querySelector(el);
    }
    let observer_options = options.observer_options ?? {};
    let observer = new IntersectionObserver((entries)=>{
        entries.forEach(entry=>{
            if( entry.isIntersecting ) {
                if( typeof options.on === 'function' ) {
                    options.on(entry, observer);
                }
            }
            else {
                if( typeof options.off === 'function' ) {
                    options.off(entry, observer);
                }
            }
        })
    }, observer_options)
    observer.observe(el);
}

export function items_animation_delay(el, items_selector = false, interval = 150){
    if( typeof el === 'string' ) el = document.querySelector(el);
    if( !el ) return;
    let children = items_selector ? el.querySelectorAll(items_selector) : el.childNodes;
    let time = 0;
    children.forEach(item=>{
        item.style.animationDelay = time + 'ms';
        time += interval;
    });
}

export function scroll_to(items, options = {}){
    if( typeof items === 'string' ) {
        items = document.querySelectorAll(items);
    }
    let default_offset = options.default_offset ?? 0;
    items.forEach(item=>{
        item.addEventListener('click',(e)=>{
            e.preventDefault();
            let target = document.querySelector(item.attributes.href.value);
            if( !target) return;
            let offset = item.dataset.offset ?? default_offset;
            let top = target.offsetTop - parseInt(offset);
            window.scrollTo({
                top,
                behavior: "smooth",
            });
        })
    })
}