import { onscreen } from "../../js/functions";

export default class Rotating_Items {

    constructor(el, options = {}){
        if ( typeof el === 'string' ) {
            el = document.querySelector(el);
        }
        this.el = el;
        this.options = options;
        this.interval_duration = options.interval ?? 3000;
        this.animation = options.animation ?? 'fade-in 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both';
        this.init();
    }

    init(){
        this.current_index = 1;
        this.current = this.el.children[this.current_index-1];

        onscreen(this.el, {
            on: ()=>{
                this.play();
            },
            off:()=>{
                this.stop();
            },
        });
    }

    next(){

        if( this.current_index === this.el.children.length ) {
            this.current_index = 1; // reset to first item
        } else {
            this.current_index++;
        }

        // hide previous
        this.current.style.display = 'none';

        this.current = this.el.children[this.current_index-1];
        this.current.style.animation = this.animation;
        this.current.style.display = '';
    }

    play(){
        this.interval = setInterval(()=>{
            this.next();
        }, this.interval_duration);        
    }

    stop(){
        clearInterval(this.interval);
    }

}
