const supported_file_types = [
    'png', 'jpg', 'webp',
];

export default function retina_support(){
    if( devicePixelRatio < 2 ) return;
    
    document.querySelectorAll('.retina img').forEach(img=>{

        let extension = img.src.split('.').pop();
        if( supported_file_types.indexOf(extension) === -1 ) return;

        let search = '.'+extension;
        let replace_with = '@2x.'+extension;

        if( img.src.indexOf(replace_with) !== -1 ) return; // already @2x

        let src_2x = img.src.replace(search, replace_with);

        fetch(src_2x, { method: 'HEAD' }).then(res => {
            if (res.ok) {
                img.src = src_2x;
            }
        });
    })

}