export default class Sticky_Element {

    constructor(element, options={}){

        if( typeof element === 'string' ) {
            element = document.querySelector(element);
        }
        this.element = element;

        this.options = options;

        this.top = options.top ?? 0;
        this.observer_options = options.observer_options ?? {};

        this.activate_callback = options.on_activate ?? null;
        this.deactivate_callback = options.on_deactivate ?? null;
        this.toggle_class = options.toggle_class ?? 'stick_active';

        this.state = 'enabled';

        this.init();
    }
    
    init(){
        this.create_container();
        this.calculate_dimensions();
        this.observer_init();
    }

    create_container(){
        this.container = document.createElement('div');
        this.container.classList.add('sticky_spacer');
        this.element.after(this.container);
        this.container.append(this.element);
    }

    calculate_dimensions(){
        // set spacer to avoid jumping on activate
        this.container.style.minHeight = this.container.offsetHeight + 'px'; 

        // set width for position fixed activate
        this.element.style.width = this.element.offsetWidth + 'px';
    }

    observer_init(){

        this.observer = new IntersectionObserver((entries, observer)=>{
            if( this.state == 'disabled' ) return;
            entries.forEach((entry) => {
                if( !entry.isIntersecting ) {
                    this.on_activate();
                } else {
                    this.on_deactivate();
                }
            })
        }, this.observer_options);

        this.observer.observe(this.container);
    }

    on_activate(){

        this.stick();

        if( typeof this.activate_callback === 'function' ) {
            this.activate_callback(this.element, this.observer);
        }
    }
    
    on_deactivate(){

        this.unstick();

        if( typeof this.deactivate_callback === 'function' ) {
            this.deactivate_callback(this.element, this.observer);
        }
    }

    stick(){
        this.element.classList.add(this.toggle_class);
        this.element.style.position = 'fixed';
        this.element.style.top = this.top;
        this.element.style.zIndex = 100;
    }

    unstick(){
        this.element.classList.remove(this.toggle_class);
        this.element.style.position = '';
        this.element.style.top = '';
        this.element.style.zIndex = '';
    }

    refresh(){
        this.container.style.minHeight = '';
        this.element.style.width = '';
        this.calculate_dimensions();
        // this.observer_init();
    }

    enable(){
        this.state = 'enabled';
    }

    disable(){
        this.state = 'disabled';
        this.unstick();
        this.container.style.minHeight = ''; 
        this.element.style.width = '';
    }
}