export function assign_element(selector, multiple = false){

    if( typeof selector === 'string' ) {

        if( multiple ) {
            return document.querySelectorAll(selector);
        }
        else {
            return document.querySelector(selector);
        }
        
    }
    else if( typeof selector === 'object' ) {
        return selector;
    }

    return null;
}

export function add_classes(item, add_classes){
    add_classes.split(' ').forEach(add_class=>{
        item.classList.add(add_class);
    });
}

export function create_element({ id = '', class_name = '', append_to = '', prepend_to = '', tag = 'div', text = '', anonymous = false }){
    if( !class_name ) class_name = id;

    let element = document.createElement(tag);

    if( !anonymous && id ) {
        id = element;
    }

    if( class_name ) {
        add_classes(element, class_name);
    }
    
    if( text ) {
        element.textContent = text;
    }

    if( append_to ) {
        if( typeof append_to === 'string' ) {
            append_to = document.querySelector(append_to);
        }
        append_to.append(element);
    }

    if( prepend_to ) {
        if( typeof prepend_to === 'string' ) {
            prepend_to = document.querySelector(prepend_to);
        }
        prepend_to.prepend(element);
    }
    
    return element;
}

export function create_dom(html){
    let temp_element = document.createElement('div');
    temp_element.innerHTML = html;
    return temp_element.children[0];
}

export function get_array_item(key, value, arr) {
    for( let i = 0; i < arr.length; i++ ) {
        if( arr[i][key] == value ) {
            return arr[i];
        }
    }
    return null;
}

export function link_with_protocol(link){
    if( !link ) return '';

    if( link.indexOf('://') === -1 ) {
        link = 'https://' + link;
    }

    return link;
}

export function link_no_protocol(link){

    if( !link ) return '';
    
    link = link.replace('http://', '', link);
    link = link.replace('https://', '', link);

    // remove www
    link = link.replace('www.', '');

    // remove trailing slash
    link = link.replace(/\/$/, "");

    return link;
}

export function apply_template(template, item){
    for(let key in item) {
        template = template.replaceAll('{{item.'+ key +'}}', item[key]);
    }
    return template;
}

export function hover_intent(el, options = { duration: 100, on_hover: null, on_hover_out: null }){
    if( typeof el.hover_intent !== 'undefined' ) return;
    el.hover_intent = null;
    
    if( typeof options.on_hover === 'function' ) {
        el.addEventListener('pointerenter', ()=>{
            clearTimeout(el.hover_intent);
            el.hover_intent = setTimeout(()=>{
                options.on_hover(el);
            }, options.duration);
        });
    }
    
    if( typeof options.on_hover_out === 'function' ) {
        el.addEventListener('pointerleave', ()=>{
            clearTimeout(el.hover_intent);
            el.hover_intent = setTimeout(()=>{
                options.on_hover_out(el);
            }, options.duration);
        });
    }
}

export function sanitize_title(text){
    return text.toLowerCase().replaceAll(' ', '-');
}

export function animate_items(items, options = {}){

    if( typeof items === 'string' ) {
        items = document.querySelectorAll(items);
    }

    let stagger = options.stagger ?? 50;
    let limit = options.limit ?? 30;
    let animation = options.animation ?? 'fade-in-bottom-short 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both';

    let stagger_increment = 0;
    let item_count = 0;
    items.forEach(item=>{

        if( item_count < limit ) {
            item.style.animation = animation;
            item.style.animationDelay = stagger_increment + 'ms';
        }

        item.style.display = '';

        stagger_increment += stagger;
        item_count++;
    })
}

export function horizontal_scroll(items, options = {}){

    if( typeof items === 'string' ) {
        items = document.querySelectorAll(items);
    }
    
    let scroll_distance = options.scroll_distance ?? 400;
    let animation_duration = options.animation_duration ?? 300;

    items.forEach(item=>{   

        if( item.scrollWidth <= item.clientWidth ) return;

        item.scroll_animating = false;
    
        item.addEventListener("wheel", function (e) {
            e.preventDefault();
    
            if( item.scroll_animating ) return;
            item.scroll_animating = true;
            
            item.scroll({
                left: item.scrollLeft +  (e.deltaY > 0 ? scroll_distance : -scroll_distance),
                behavior: 'smooth',
            })
    
            setTimeout(function(){
                item.scroll_animating = false;
            }, animation_duration);
    
        });
    })

}

export function display_message( options ){

    const html = document.createElement('div');
    html.textContent = options.text;

    html.classList.add('msg');

    if( typeof options.class !== 'undefined' ) {
        html.classList.add(options.class);
    }

    // placement
    if( typeof options.after !== 'undefined' ) {
        options.after.after(html);
    }
    else if( typeof options.before !== 'undefined' ) {
        options.before.before(html);
    }

    if( typeof options.remove !== 'undefined' ) {
        setTimeout(()=>{
            html.remove();
        }, options.remove);
    }

}

export function get_animation_duration(el){
    return get_time_value(window.getComputedStyle(el).animationDuration);
}

function get_time_value(value){
    let temp = value.toString();
    if( temp.indexOf('ms') !== -1 ) {
        return parseFloat(value);
    }
    else if( temp.indexOf('s') !== -1 ) {
        return parseFloat(value) * 1000;
    }
    return null;
}

export function format_date(date, format = 'm/d/y'){

    let y = date.getFullYear();

    let m = date.getMonth()+1;
    if( m < 10 ) {
        m = '0' + m;
    }

    let d = date.getDate();
    if( d < 10 ) {
        d = '0' + d;
    }

    let date_format = {
        m,d,y
    }

    let date_format_output = format;
    for(let key in date_format) {
        date_format_output = date_format_output.replace(key, date_format[key]);
    }
    
    return date_format_output;
}

export function get_offset_top(el){
    let total = 0;
    get_parent_offset(el);
    function get_parent_offset(el){
        if( el.parentElement.nodeName === 'BODY' ) {
            return total;
        }
        else {
            total += el.parentElement.offsetTop;
            get_parent_offset(el.parentElement);
        }
    }
    return total;
}